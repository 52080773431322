import React, { useEffect, useRef } from "react";
import "./App.css";
import alanBtn from "@alan-ai/alan-sdk-web";


function App() {
  const alanBtnContainer = useRef();
  const logoEl = useRef();

  useEffect(() => {
    alanBtn({
      key:
        "07a27b49d908ba7398cbcdec3903fefc2e956eca572e1d8b807a3e2338fdd0dc/stage",
      rootEl: alanBtnContainer.current,
      onCommand: (commandData) => {
        console.log(commandData);
        if (commandData.command === "go:back") {
          if (logoEl.current) {
            logoEl.current.style.transform = "rotate(180deg)";
          }
        }
      },
    });
  }, []);
  return (
    <div >
      <div ref={alanBtnContainer}></div>
    </div>
  );
}

export default App;
